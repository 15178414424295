import ColumnTitle from 'components/ColumnTitle';
import { COLUMN_TYPES } from 'core/utils/constants';
import { isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import {
  loadCommunicationTables,
  loadTableData,
  updateTable
} from 'redux/ui/clientInteractions/operations';
import { deleteColumn, expandTableRow } from 'redux/ui/clientInteractions/reducer';
import { Button, ConfigProvider, Empty } from 'antd';
import { MinusSquare, PlusSquare } from 'react-feather';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { SCol, SRow, SText } from 'components/Standard';
import WarningIcon from 'assets/images/warning-icon.svg';
import { getUIClientInteractions } from 'redux/selectors/uiClientInterationsTable';
import { ArrowRightOutlined } from '@ant-design/icons';
import ColumnsRender from './ColumnsRender';
import { ClientInteractionsStyledTable } from './styled';
import { StyledEmpty } from '../../DashboardPage/styled';
import SButton from '../../../components/Standard/SButton';
import { questionsResource } from '../../../redux/resources/questions';
import { isCustomFieldVisible } from '../../../core/utils/isCustomFieldVisible';

const sortableColumns = ['duration', 'startedAt', 'reviewCreatedAt', 'operatorId'];
const tableScroll = { y: 'calc(100vh - 148px)', x: 'max-content' };

const shouldCellUpdate = (reRender = false) => (record, prevRecord) =>
  !isEqual(record.reviews, prevRecord.reviews) || reRender;

const ClientInteractionsTable = ({ selectedWorkPlanTaskConfigurationId, hasConflicts }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectCustomFieldsByKeys = useMemo(() => getCustomFieldsByKeysForReviews, []);

  const { page, totalCount, sort, loading: listLoading } = useSelector(
    state => state.clientInteractionsList,
    shallowEqual
  );

  const {
    tableLoading,
    tableRows,
    tableFilters,
    tableCustomFieldFilters,
    tableColumns,
    tableId
  } = useSelector(getUIClientInteractions, shallowEqual);

  const expandedTableRowsKeys = useSelector(
    state => state.uiClientInteractions.expandedTableRowsKeys,
    isEqual
  );

  const customFieldsByKeys = useSelector(state => selectCustomFieldsByKeys(state, true), isEqual);
  const questionsChecklist = useSelector(state => state.questionsResource.byIds, isEqual);
  const tablesLoading = useSelector(state => state.communicationTablesResource.loading);

  const loading = listLoading || tableLoading || tablesLoading;
  const dataSource = hasConflicts && !loading ? [] : tableRows;

  useEffect(() => {
    dispatch(loadCommunicationTables());
    return () => dispatch(actions.setDefaultState());
  }, []);

  useEffect(() => {
    tableColumns.forEach(column => {
      if (column.startsWith('custom_field/')) {
        if (!isCustomFieldVisible(customFieldsByKeys[column])) {
          dispatch(deleteColumn(column));
        }
      }
    });
  }, [tableColumns]);
  useEffect(() => {
    if (!loading && tableId) {
      dispatch(
        loadTableData({
          tableId,
          page,
          filters: tableFilters,
          customFieldFilters: tableCustomFieldFilters,
          sort,
          include: 'client,operator.role,operator.unit,status,text_communication_parts.operator'
        })
      );
    }
  }, [tableId, page.number, page.size, sort]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const updateFilters = isEmpty(tableFilters?.taskAssignmentsIds)
        ? tableFilters
        : { taskAssignmentsIds: tableFilters?.taskAssignmentsIds };

      if (selectedWorkPlanTaskConfigurationId) {
        dispatch(
          updateTable({
            id: tableId,
            filters: updateFilters,
            customFieldFilters: tableCustomFieldFilters,
            page: {
              size: pagination.pageSize.toString(),
              number: pagination.current.toString()
            }
          })
        );
      }
      if (!selectedWorkPlanTaskConfigurationId) {
        dispatch(
          actions.updatePage({
            size: pagination.pageSize.toString(),
            number: pagination.current.toString()
          })
        );
      }

      dispatch(actions.setSorting(sorter));
    },
    [dispatch, selectedWorkPlanTaskConfigurationId, tableFilters]
  );

  const onExpand = useCallback(
    (e, record) => {
      e.stopPropagation();
      return !isEmpty(record.children) && dispatch(expandTableRow(record.id));
    },
    [dispatch]
  );

  const onRowExpand = useCallback(record => dispatch(expandTableRow(record.id)), [dispatch]);

  const pagination = useMemo(
    () => ({
      showQuickJumper: {
        goButton: (
          <SButton
            style={{ marginLeft: '-8px', border: 'none', verticalAlign: 'middle', height: '100%' }}
            icon={<Icon icon={ArrowRightOutlined} />}
          />
        )
      },
      locale: { jump_to: 'Перейти на' },
      pageSize: parseInt(page.size),
      current: parseInt(page.number),
      total: totalCount,
      showSizeChanger: true,
      pageSizeOptions: ['10', '25', '50', '100', '150', '200']
    }),
    [totalCount, page.size, page.number]
  );

  const columnRender = useCallback(
    (col, customFieldsByKeys, questionsChecklist) => (text, record) => (
      <ColumnsRender
        onRowExpand={onRowExpand}
        customFieldsByKeys={customFieldsByKeys}
        questionsChecklist={questionsChecklist}
        col={col}
        text={text}
        record={record}
      />
    ),
    [onRowExpand, customFieldsByKeys]
  );

  const customFieldKeysSet = new Set(Object.keys(customFieldsByKeys));

  const filteredTableColumns = tableColumns.filter(column => {
    return customFieldKeysSet.has(column) || !column.startsWith('custom_field/');
  });

  const columns = useMemo(
    () =>
      tableColumns.map(col => ({
        title: (
          <ColumnTitle
            columnType={col}
            customFieldsByKeys={customFieldsByKeys}
            questionsChecklist={questionsChecklist}
            tableView
          />
        ),
        key: col,
        dataIndex: col,
        render: columnRender(col, customFieldsByKeys, questionsChecklist),
        shouldCellUpdate:
          col === COLUMN_TYPES.communicationType.value
            ? undefined
            : shouldCellUpdate(!isEmpty(customFieldsByKeys)),
        sorter: sortableColumns.includes(col),
        width: COLUMN_TYPES[col]?.width || 200,
        textWrap: 'word-break',
        ellipsis: true
      })),
    [tableColumns, customFieldsByKeys]
  );

  const renderDescription = () => {
    if (hasConflicts && !loading) {
      return (
        <SCol span={24}>
          <SText color="#333333" fontSize="21px" fontWeight="600" style={{ lineHeight: '25px' }}>
            {t('clientInteractionsPage.conflicts.title')}
          </SText>
          <SRow align="center">
            <SText
              maxWidth="396px"
              type="secondary"
              style={{ lineHeight: '20px', marginTop: '10px' }}
            >
              {t('clientInteractionsPage.conflicts.description1')}
            </SText>
          </SRow>
          <SRow align="center">
            <SText
              maxWidth="396px"
              type="secondary"
              style={{ lineHeight: '20px', marginBottom: '34px' }}
            >
              {t('clientInteractionsPage.conflicts.description2')}
            </SText>
          </SRow>
        </SCol>
      );
    }
  };

  return (
    <ConfigProvider
      renderEmpty={() => (
        <StyledEmpty
          image={
            hasConflicts && !loading ? (
              <img src={WarningIcon} alt="warning" width="70px" />
            ) : (
              Empty.PRESENTED_IMAGE_SIMPLE
            )
          }
          description={renderDescription()}
        />
      )}
    >
      <ClientInteractionsStyledTable
        expandable={{
          // * looks like row click not working
          expandRowByClick: true,
          expandedRowKeys: expandedTableRowsKeys,
          expandIcon: ({ record, expanded }) => {
            const exp = expandedTableRowsKeys.includes(record.id);

            if (isEmpty(record?.children))
              return (
                <button className="ant-table-row-expand-icon ant-table-row-expand-icon-spaced" />
              );

            if (exp || expanded) {
              return (
                <Button
                  onClick={e => onExpand(e, record)}
                  size="small"
                  type="link"
                  style={{ float: 'left' }}
                  icon={<Icon icon={MinusSquare} />}
                />
              );
            }

            return (
              <Button
                size="small"
                type="link"
                style={{ float: 'left' }}
                onClick={e => onExpand(e, record)}
                icon={<Icon icon={expanded ? MinusSquare : PlusSquare} />}
              />
            );
          }
        }}
        size="small"
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        border
        scroll={tableScroll}
        pagination={hasConflicts ? false : pagination}
        onChange={handleTableChange}
      />
    </ConfigProvider>
  );
};

export default ClientInteractionsTable;
